import React from 'react';
import { Outlet } from 'react-router-dom';

const Statistic = () => {
  return (
    <main id="statistic-page" className="min-h-[calc(100vh-64px)]">
      <Outlet />
    </main>
  );
};

export default Statistic;
