import { format as formatDate, isValid } from 'date-fns';

export class DateUtils {
  static readonly FORMAT_READABLE = 'dd/MM/yyyy';
  static readonly FORMAT_READABLE_FULL = 'dd/MM/yyyy HH:ss';
  static readonly FORMATE_DATE_PARAM = 'MM/dd/yyyy HH:ss aa';

  static toUnixTimestamp = (date: Date, divisor = 1000): number => {
    return date.getTime() / divisor;
  };

  static format = (
    date: Date | string,
    formatStr = DateUtils.FORMAT_READABLE,
  ): string => {
    if (!isValid(new Date(date))) throw new Error('date is not valid');
    return formatDate(new Date(date), formatStr);
  };
}
