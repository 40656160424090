import {
  Popover,
  PopoverButton,
  PopoverPanel,
  Transition,
} from '@headlessui/react';
import { Bars3BottomLeftIcon, UserCircleIcon } from '@heroicons/react/24/solid';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { Link, Outlet } from 'react-router-dom';
import DigimeLogo from '../../assets/images/logo-digime.svg';
import MQTTToast from '../../components/MQTTToast';
import Sidebar from '../../components/Sidebar';
import Toast from '../../components/Toast';
import { BreadcrumbsProvider } from '../../contexts/BreadcrumbsContext';
import { ToastProvider } from '../../contexts/ToastContext';
import { WSProvider } from '../../contexts/WebSocketContext';
import { useAuth } from '../../handlers/authHandler';
import { Role } from '../../types/common';

const Dashboard = () => {
  const [open, setOpen] = useState(false);
  const [profile, setProfile] = useState({});

  const { user } = useAuth();

  useEffect(() => {
    if (user['username']) {
      setProfile(user);
      return;
    }
    user.then((data) => setProfile(data));
  }, []);

  return (
    <BreadcrumbsProvider>
      <div className="bg-slate-100 min-h-screen relative">
        <div className="bg-white px-4 shadow-sm fixed top-0 z-10 sm:z-30 w-full flex items-center justify-between">
          <div className="flex items-center">
            <button
              onClick={() => setOpen(!open)}
              type="button"
              className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200">
              <span className="sr-only">Open sidebar</span>
              <Bars3BottomLeftIcon className="size-6" />
            </button>
            <Link to="/">
              <img src={DigimeLogo} />
            </Link>
          </div>

          <div className="flex items-center">
            <Link to="/dashboards">
              <div className="p-2 border border-orange-600 rounded-md text-orange-600 text-xs sm:text-sm font-medium hover:bg-gray-50 shadow cursor-pointer me-2.5">
                Dashboards
              </div>
            </Link>

            {profile && profile['role'] === Role.ADMIN && (
              <Link to="/settings">
                <div className="p-2 bg-orange-600 rounded-md text-white text-xs sm:text-sm font-medium shadow cursor-pointer hover:bg-orange-700">
                  Settings
                </div>
              </Link>
            )}

            <Popover className="flex items-center">
              <div className="flex items-center ms-3">
                <div>
                  <PopoverButton
                    type="button"
                    className="flex text-sm rounded-full focus:ring-4 focus:ring-orange-300"
                    aria-expanded="false"
                    data-dropdown-toggle="dropdown-user">
                    <span className="sr-only">Open user menu</span>
                    <UserCircleIcon className="size-8" />
                  </PopoverButton>
                </div>
                <PopoverPanel
                  transition
                  anchor="bottom start"
                  className="z-50 my-4 text-base list-none bg-white divide-y divide-gray-100 rounded shadow dark:bg-gray-700 dark:divide-gray-600 transition duration-200 ease-out data-[closed]:scale-95 data-[closed]:opacity-0"
                  id="dropdown-user">
                  <div className="px-4 py-3" role="none">
                    <p
                      className="text-sm text-gray-900 dark:text-white"
                      role="none">
                      {profile?.full_name || profile?.username}
                    </p>
                    <p
                      className="text-sm font-medium text-gray-900 truncate dark:text-gray-300"
                      role="none">
                      Digime Company
                    </p>
                  </div>
                  <ul className="py-1" role="none">
                    <li>
                      <a
                        href="/logout"
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white">
                        Sign out
                      </a>
                    </li>
                  </ul>
                </PopoverPanel>
              </div>
            </Popover>
          </div>
        </div>

        <Transition show={open}>
          <div
            className={clsx([
              // Base styles
              'fixed top-0 z-30 border transition ease-in-out h-full px-3 py-4 overflow-y-auto bg-white w-64',
              // Shared closed styles
              'data-[closed]:opacity-0',
              // Entering styles
              'data-[enter]:duration-200 data-[enter]:data-[closed]:-translate-x-full',
              // Leaving styles
              'data-[leave]:duration-300 data-[leave]:data-[closed]:-translate-x-full',
            ])}>
            <Sidebar />
          </div>
        </Transition>

        <div
          className={clsx([
            'transition ease-in-out delay-150 sm:hidden absolute bg-gray-500 bg-opacity-50',
            open ? 'opacity-100 w-full h-full z-20 top-0' : 'opacity-0',
          ])}
          onClick={() => setOpen(false)}></div>

        <aside className="fixed top-0 left-0 z-20 w-64 h-screen transition-transform -translate-x-full sm:translate-x-0">
          <div className="h-full px-3 py-4 overflow-y-auto bg-white border-r">
            <Sidebar />
          </div>
        </aside>

        <ToastProvider>
          <WSProvider>
            <div className="p-4 sm:ml-64 pt-16 min-h-screen relative">
              <Outlet />
              <MQTTToast />
              <Toast />
            </div>
          </WSProvider>
        </ToastProvider>
      </div>
    </BreadcrumbsProvider>
  );
};

export default Dashboard;
