import React, { useCallback } from 'react';

export const useBoolean = (initValue = false) => {
  const [state, setState] = React.useState<boolean>(initValue);

  const setTrue = useCallback(() => {
    setState(true);
  }, []);

  const setFalse = React.useCallback(() => {
    setState(false);
  }, []);

  const toggle = React.useCallback(() => {
    setState((prevState) => !prevState);
  }, []);

  return {
    state,
    setTrue,
    setFalse,
    toggle,
  };
};
