import React from 'react';
import { getNumberJobStateInNodeApi } from 'src/handlers/apiCallHandler';
import { useBoolean, useDateRangeParams } from 'src/hooks';
import { DateUtils } from 'src/utils';
import BaseBarChart, { BarColorsEnum, BarSeries } from '../BaseBarChart';
import { BaseDatePicker } from '../BaseDatePicker';
import { Option } from '../BaseSelectDropdown';

interface NumberJobStateInNodeChartProps {
  onMounted: (options: Option[]) => void;
}

export const NumberJobStateInNodeChart = ({
  onMounted,
}: NumberJobStateInNodeChartProps) => {
  const {
    state: loading,
    setTrue: startLoading,
    setFalse: endLoading,
  } = useBoolean();

  //prettier-ignore
  const { queryParams, maxStartDate, minEndDate, handleQueryParamsChange } = useDateRangeParams();
  const [series, setChartSeries] = React.useState<BarSeries>([]);
  const [categories, setCategories] = React.useState<string[]>([]);

  React.useEffect(() => {
    if (!queryParams.end_date || !queryParams.start_date) return;
    startLoading();
    const series: BarSeries = [
      { name: 'Jobs Running', data: [], color: BarColorsEnum.running },
      { name: 'Jobs Success', data: [], color: BarColorsEnum.success },
      { name: 'Jobs Failed', data: [], color: BarColorsEnum.failed },
    ];
    const categories: string[] = [];
    const nodeList: Option[] = [];

    getNumberJobStateInNodeApi({
      end_date: DateUtils.toUnixTimestamp(queryParams.end_date),
      start_date: DateUtils.toUnixTimestamp(queryParams.start_date),
    })
      .then((response) => {
        response.data.forEach((item, index) => {
          categories[index] = item.node.node_name;
          nodeList[index] = {
            label: item.node.node_name,
            value: item.node.node_id,
          };
          series[0].data[index] = item.num_running;
          series[1].data[index] = item.num_success;
          series[2].data[index] = item.num_failed;
        });
      })
      .finally(() => {
        setCategories(categories);
        setChartSeries(series);
        onMounted(nodeList);
        endLoading();
      });
  }, [onMounted, queryParams.end_date, queryParams.start_date]);

  return (
    <div className="chart flex flex-col">
      <div className="chart-options flex-shrink-0 relative z-10">
        <div className="flex justify-end gap-2 mb-2">
          <BaseDatePicker
            placeholderText="Select Start Date"
            maxDate={maxStartDate}
            date={queryParams.start_date}
            onChange={(date) => handleQueryParamsChange('start_date', date)}
            disabled={loading}
          />
          <BaseDatePicker
            placeholderText="Select End Date"
            minDate={minEndDate}
            date={queryParams.end_date}
            onChange={(date) => handleQueryParamsChange('end_date', date)}
            disabled={loading}
          />
        </div>
      </div>
      <div className="chart-main flex-grow relative z-0">
        <BaseBarChart
          title="Statistic Number Jobs State"
          categories={categories}
          series={series}
        />
      </div>
    </div>
  );
};
