import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import qs from 'qs';
import queryString from 'query-string';
import { ApiKeyCreation } from '../types/apiKey';
import {
  ContainerActionCommand,
  ContainerDeployment,
  ContainerRemoval,
} from '../types/cluster';
import { OperatingSystem } from '../types/common';
import { ModelVersionControlCreation } from '../types/modelVersionControl';
import { PermissionCreation, RolePermissionRequest } from '../types/permission';
import { UserCreation } from '../types/user';
import {
  InstallationGuideResponse,
  NodeExecutionTimeParams,
  NodeExecutionTimeSchema,
  NodeJobStateDetailParams,
  NodeJobStateDetailSchema,
  NodeJobStateParams,
  NodeJobStateSchema,
  NumberJobNodeResponse,
  NumberJobStateInNodeResponse,
  StatisticAPIKeyParams,
  StatisticAPIKeyResponse,
  StatisticAPIKeySchema,
  StatisticDetailAPIKeyParams,
  StatisticDetailAPIKeyResponse,
  StatisticDetailAPIKeySchema,
  TotalExecutionTimeParams,
  TotalExecutionTimeResponse,
  TotalExecutionTimeSchema,
  TotalNodeExecutionTimeResponse,
} from './schema';

const HOST =
  process.env.NODE_ENV == 'production'
    ? '/api/v1'
    : 'http://localhost:8000/api/v1';

const unauthenInstance = axios.create({
  baseURL: HOST,
});

export const authenInstance = axios.create({
  baseURL: HOST,
  withCredentials: true,
});

export const login = async (username: string, password: string) => {
  try {
    const token = await unauthenInstance.post(
      '/me/login',
      qs.stringify({ username, password }),
    );

    authenInstance.defaults.headers.common['Authorization'] =
      `Bearer ${token.data.access_token}`;
    localStorage.setItem('digime_access_token', token.data.access_token);

    return {
      token: token.data.access_token,
      expired_time: jwtDecode(token.data.access_token)['exp'],
    };
  } catch (error) {
    return false;
  }
};

export const getMQTTCredential = async () => {
  const mqttCredentials = await authenInstance.get('/mqtt/credentials');
  return mqttCredentials.data;
};

export const getNodeStates = async () => {
  const nodeStates = await authenInstance.get('/nodes/states');
  return nodeStates.data;
};

export const getStatesByNodeId = async (nodeId: string) => {
  const nodeStates = await authenInstance.get(`/nodes/${nodeId}/states`);
  return nodeStates.data.data;
};

export const getContainersByNodeId = async (nodeId: string) => {
  const containers = await authenInstance.get(`/nodes/${nodeId}/containers`);
  return containers.data.data;
};

export const getResourcesByContainerId = async (
  nodeId: string,
  containerId: string,
) => {
  const resources = await authenInstance.get(
    `/nodes/${nodeId}/containers/${containerId}/used_resources`,
  );
  return resources.data;
};

export const getAllNodes = async () => {
  const nodes = await authenInstance.get('/nodes');
  return nodes.data;
};

export const deployAContainer = async (data: ContainerDeployment) => {
  const container = await authenInstance.post(
    '/docker/containers/deploy',
    data,
  );
  return container.data;
};

export const removeAContainer = async (data: ContainerRemoval) => {
  const result = await authenInstance.post(
    `/nodes/${data.nodeId}/docker/containers/${data.containerId}/remove`,
  );
  return result;
};

export const getAllRegistries = async () => {
  const result = await authenInstance.get(`/registries`);
  return result;
};

export const getAllImageByRegistryId = async (registryId: string) => {
  const result = await authenInstance.get(
    `/registries/${registryId}/docker/images`,
  );
  return result;
};

export const deployContainerByYamlFile = async (yamlCode: string) => {
  const result = await authenInstance.post(`/docker/containers/yaml/deploy`, {
    yaml_payload: yamlCode,
  });
  return result.data;
};

export const getAllApiKeys = async (offset = 0, limit = 20) => {
  const result = await authenInstance.get(
    `/api-keys?offset=${offset}&limit=${limit}`,
  );
  return result.data.data;
};

export const getApiKeyById = async (apiKeyId: string) => {
  const result = await authenInstance.get(`/api-keys/${apiKeyId}`);
  return result.data.data;
};

export const getAllPermissions = async (offset = 0, limit = 100) => {
  const result = await authenInstance.get(
    `/permissions?offset=${offset}&limit=${limit}`,
  );
  return result.data.data;
};

export const getAllModelVersionControls = async (offset = 0, limit = 100) => {
  const result = await authenInstance.get(
    `/model-version-controls?offset=${offset}&limit=${limit}`,
  );
  return result.data.data;
};

export const updateApiKeyByIdentifier = async (
  apiKeyId: string,
  body: ApiKeyCreation,
) => {
  const result = await authenInstance.put(`/api-keys/${apiKeyId}`, body);
  return result.data.data;
};

export const createApiKey = async (body: ApiKeyCreation) => {
  const result = await authenInstance.post(`/api-keys`, body);
  return result.data.data;
};

export const deleteApiKeyByIdentifier = async (apiKeyId: string) => {
  const result = await authenInstance.delete(`/api-keys/${apiKeyId}`);
  return result.data.data;
};

export const updatePermissionByIdentifier = async (
  permissionId: string,
  body: PermissionCreation,
) => {
  const result = await authenInstance.patch(
    `/permissions/${permissionId}`,
    body,
  );
  return result.data.data;
};

export const createPermission = async (body: PermissionCreation) => {
  const result = await authenInstance.post(`/permissions`, body);
  return result.data.data;
};

export const deletePermissionByIdentifier = async (permissionId: string) => {
  const result = await authenInstance.delete(`/permissions/${permissionId}`);
  return result.data.data;
};

export const createModelVersionControl = async (
  body: ModelVersionControlCreation,
) => {
  const result = await authenInstance.post(`/model-version-controls`, body);
  return result.data.data;
};

export const getModelVersionControlByIdentifier = async (
  modelVersionControlId: string,
) => {
  const result = await authenInstance.get(
    `/model-version-controls/${modelVersionControlId}`,
  );
  return result.data.data;
};

export const updateModelVersionControlByIdentifier = async (
  modelVersionControlId: string,
  body: ModelVersionControlCreation,
) => {
  const result = await authenInstance.put(
    `/model-version-controls/${modelVersionControlId}`,
    body,
  );
  return result.data.data;
};

export const deleteModelVersionControlByIdentifier = async (
  modelVersionControlId: string,
) => {
  const result = await authenInstance.delete(
    `/model-version-controls/${modelVersionControlId}`,
  );
  return result.data.data;
};

export const deleteNodeByIdentifier = async (nodeId: string) => {
  const result = await authenInstance.delete(`/nodes/${nodeId}`);
  return result.data.data;
};

export const getNodeById = async (nodeId: string) => {
  const result = await authenInstance.get(`/nodes/${nodeId}`);
  return result.data.data;
};

export const makeRequest = async (
  url: string,
  method: string,
  payload: string,
) => {
  const result = await unauthenInstance(url, {
    method,
    data: JSON.parse(payload),
  });
  return result.data;
};

export const getJobByIdWithApiKey = async (jobId: string, apiKey: string) => {
  const result = await unauthenInstance.get(
    `/ml/prediction/jobs/${jobId}?api_key=${apiKey}`,
  );
  return result.data;
};

export const interactContainer = async (
  nodeId: string,
  containerId: string,
  payload: ContainerActionCommand,
) => {
  const result = await authenInstance.post(
    `/nodes/${nodeId}/containers/${containerId}/interact`,
    payload,
  );
  return result.data;
};

export const getProfile = async () => {
  const result = await authenInstance.get(`/me`);
  return result.data;
};

export const getAllUsers = async (offset = 0, limit = 100) => {
  const result = await authenInstance.get(
    `/users?offset=${offset}&limit=${limit}`,
  );
  return result.data;
};

export const createUser = async (user: UserCreation) => {
  const result = await authenInstance.post(`/users`, user);
  return result.data;
};

export const updateUser = async (userId: string, user: UserCreation) => {
  const result = await authenInstance.put(`/users/${userId}`, user);
  return result.data;
};

export const getAllRolePermissions = async (offset = 0, limit = 30) => {
  const result = await authenInstance.get(
    `/roles/permissions?offset=${offset}&limit=${limit}`,
  );
  return result.data.data;
};

export const getAllRoleGroups = async (offset = 0, limit = 30) => {
  const result = await authenInstance.get(
    `/roles/groups?offset=${offset}&limit=${limit}`,
  );
  return result.data.data;
};

export const updateRolePermissions = async (
  rolePermission: RolePermissionRequest,
) => {
  const result = await authenInstance.post(
    `/roles/permissions/apply`,
    rolePermission,
  );
  return result.data;
};

export const getInstallationGuide = async (
  os: OperatingSystem,
): Promise<InstallationGuideResponse> => {
  const result = await authenInstance.get(`/guide/installation?os=${os}`);
  return result.data;
};

export const getNumberJobStateInNodeApi = async (
  queryParams: NodeJobStateParams,
): Promise<NumberJobStateInNodeResponse> => {
  const body = NodeJobStateSchema.safeParse(queryParams);
  if (!body.success) {
    return Promise.reject(body.error.errors[0]);
  }

  const url = queryString.stringifyUrl({
    url: '/dashboards/nodes/number-job-state-in-node',
    query: body.data,
  });

  const result = await authenInstance.get(url);
  return result.data;
};

export const getNumberJobStateInDetailNodeApi = async (
  queryParams: NodeJobStateDetailParams,
): Promise<NumberJobNodeResponse> => {
  const body = NodeJobStateDetailSchema.safeParse(queryParams);
  if (!body.success) {
    return Promise.reject(body.error.errors[0]);
  }

  const { node_id, ...query } = body.data;

  const url = queryString.stringifyUrl({
    url: `/dashboards/nodes/${node_id}/number-job-state-in-node`,
    query,
  });

  const result = await authenInstance.get(url);
  return result.data;
};

export const getTotalExecutionTimeApi = async (
  queryParams: TotalExecutionTimeParams,
): Promise<TotalExecutionTimeResponse> => {
  const body = TotalExecutionTimeSchema.safeParse(queryParams);
  if (!body.success) return Promise.reject(body.error.errors[0]);

  const url = queryString.stringifyUrl({
    url: '/dashboards/nodes/total-execution-time',
    query: body.data,
  });

  const result = await authenInstance.get(url);
  return result.data;
};

export const getNodeExecutionTimeApi = async (
  queryParams: NodeExecutionTimeParams,
): Promise<TotalNodeExecutionTimeResponse> => {
  const body = NodeExecutionTimeSchema.safeParse(queryParams);
  if (!body.success) return Promise.reject(body.error.errors[0]);

  const { node_id, ...query } = body.data;
  const url = queryString.stringifyUrl({
    url: `/dashboards/nodes/${node_id}/execution-time`,
    query,
  });

  const result = await authenInstance.get(url);
  return result.data;
};

export const getStatisticAPIKeyApi = async (
  queryParams: StatisticAPIKeyParams,
): Promise<StatisticAPIKeyResponse> => {
  const body = StatisticAPIKeySchema.safeParse(queryParams);

  if (!body.success) return Promise.reject(body.error.errors[0]);

  const url = queryString.stringifyUrl({
    url: '/dashboards/api-keys/number-job-state',
    query: body.data,
  });

  const result = await authenInstance.get(url);

  return result.data;
};

export const getStatisticDetailAPIKeyApi = async (
  queryParams: StatisticDetailAPIKeyParams,
): Promise<StatisticDetailAPIKeyResponse> => {
  const body = StatisticDetailAPIKeySchema.safeParse(queryParams);
  if (!body.success) return Promise.reject(body.error.errors[0]);
  const { api_key, ...params } = body.data;

  const url = queryString.stringifyUrl({
    url: `/dashboards/api-keys/${api_key}/number-job-state`,
    query: params,
  });

  const result = await authenInstance.get(url);

  return result.data;
};
