import { addBusinessDays } from 'date-fns';
import React from 'react';
import { Option } from 'src/components/BaseSelectDropdown';
import { DurationType } from 'src/handlers/schema';

const dateOnly = new Date(
  new Date().getFullYear(),
  new Date().getMonth(),
  new Date().getDate(),
);

type SelectOptions = {
  start_date: Date | undefined;
  end_date: Date | undefined;
  duration: DurationType;
};

export const DurationOptions: Option<DurationType>[] = [
  { label: 'Days', value: 'day' },
  { label: 'Month', value: 'month' },
  { label: 'Year', value: 'year' },
];

export const useDateRangeParams = (
  startDate?: string | undefined,
  endDate?: string | undefined,
  offset = -30,
) => {
  const [queryParams, setQueryParams] = React.useState<SelectOptions>({
    end_date: dateOnly,
    start_date: addBusinessDays(dateOnly, offset),
    duration: 'day',
  });

  const end_date = endDate ? endDate : queryParams.end_date;
  const maxStartDate = end_date
    ? addBusinessDays(new Date(end_date), -1)
    : undefined;

  const start_date = startDate ? startDate : queryParams.start_date;
  const minEndDate = start_date
    ? addBusinessDays(new Date(start_date), 1)
    : undefined;

  const handleQueryParamsChange = (
    key: keyof SelectOptions,
    value: Date | string | undefined,
  ): void => {
    setQueryParams((prevState) => {
      return {
        ...prevState,
        [key]: value,
      };
    });
  };

  React.useEffect(() => {
    if (!startDate) return;
    setQueryParams((prevState) => {
      return {
        ...prevState,
        start_date: new Date(startDate),
      };
    });
  }, [startDate]);

  React.useEffect(() => {
    if (!endDate) return;
    setQueryParams((prevState) => {
      return {
        ...prevState,
        end_date: new Date(endDate),
      };
    });
  }, [endDate]);

  return {
    queryParams,
    minEndDate,
    maxStartDate,
    handleQueryParamsChange,
  };
};
