import React from 'react';
import { getNumberJobStateInDetailNodeApi } from 'src/handlers/apiCallHandler';
import { DurationType } from 'src/handlers/schema';
import { DurationOptions, useBoolean, useDateRangeParams } from 'src/hooks';
import { DateUtils } from 'src/utils';
import { BaseDatePicker } from '../BaseDatePicker';
import { BaseSelectDropdown, Option } from '../BaseSelectDropdown';
import BaseBarChart, { BarColorsEnum, BarSeries } from '../BaseBarChart';

interface NumberJobNodeChartProps {
  nodeList: Option[];
}

export const NumberJobNodeChart = ({ nodeList }: NumberJobNodeChartProps) => {
  const {
    state: loading,
    setTrue: startLoading,
    setFalse: endLoading,
  } = useBoolean();

  const { queryParams, minEndDate, maxStartDate, handleQueryParamsChange } =
    useDateRangeParams();

  const [categories, setCategories] = React.useState<string[]>([]);
  const [series, setSeries] = React.useState<BarSeries>([]);
  const [nodeId, setNodeId] = React.useState<string>();
  const firstMounted = React.useRef<boolean>(true);

  const title = nodeList.find((node) => node.value === nodeId)?.label;

  const handleDurationSelect = (option: Option<DurationType>): void => {
    handleQueryParamsChange('duration', option.value);
  };

  React.useEffect(() => {
    if (!nodeId || !queryParams.end_date || !queryParams.start_date) return;

    const categories: string[] = [];
    const series: BarSeries = [
      { name: 'Job Failed', data: [], color: BarColorsEnum.failed },
      { name: 'Job Running', data: [], color: BarColorsEnum.running },
      { name: 'Job Success', data: [], color: BarColorsEnum.success },
    ];

    startLoading();
    getNumberJobStateInDetailNodeApi({
      duration: queryParams.duration,
      node_id: nodeId,
      end_date: DateUtils.toUnixTimestamp(queryParams.end_date),
      start_date: DateUtils.toUnixTimestamp(queryParams.start_date),
    })
      .then((response) => {
        response.data.forEach((node, index) => {
          const { num_failed, num_running, num_success, time } = node;
          categories[index] = DateUtils.format(time);
          series[0].data[index] = num_failed;
          series[1].data[index] = num_running;
          series[2].data[index] = num_success;
        });
      })
      .finally(() => {
        setCategories(categories);
        setSeries(series);
        endLoading();
      });
  }, [
    nodeId,
    queryParams.end_date,
    queryParams.start_date,
    queryParams.duration,
  ]);

  React.useEffect(() => {
    if (nodeList.length > 0 && firstMounted.current) {
      setNodeId(nodeList[0].value);
      firstMounted.current = false;
    }
  }, [nodeList]);

  return (
    <div className="chart flex flex-col">
      <div className="chart-options flex-shrink-0">
        <div className="flex justify-end gap-2 mb-2">
          <BaseSelectDropdown
            selected={nodeId}
            options={nodeList}
            onSelect={(option) => setNodeId(option.value)}
          />
          <BaseSelectDropdown
            selected={queryParams.duration}
            options={DurationOptions}
            onSelect={handleDurationSelect}
          />
          <BaseDatePicker
            placeholderText="Select Start Date"
            maxDate={maxStartDate}
            date={queryParams.start_date}
            onChange={(date) => handleQueryParamsChange('start_date', date)}
            disabled={loading}
          />
          <BaseDatePicker
            placeholderText="Select End Date"
            minDate={minEndDate}
            date={queryParams.end_date}
            onChange={(date) => handleQueryParamsChange('end_date', date)}
            disabled={loading}
          />
        </div>
      </div>
      <div className="chart-main flex-grow ">
        <BaseBarChart title={title} categories={categories} series={series} />
      </div>
    </div>
  );
};
