import { Input, InputProps } from '@headlessui/react';
import React from 'react';
import DatePicker, { DatePickerProps } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

interface BaseDatePickerProps {
  date: DatePickerProps['date'];
  dateFormat?: DatePickerProps['dateFormat'];
  placeholderText?: DatePickerProps['placeholderText'];
  popperPlacement?: DatePickerProps['popperPlacement'];
  maxDate?: DatePickerProps['maxDate'];
  minDate?: DatePickerProps['minDate'];
  isClearable?: DatePickerProps['isClearable'];
  showTimeSelect?: DatePickerProps['showTimeSelect'];
  disabled?: DatePickerProps['disabled'];
  onChange: (date: DatePickerProps['date']) => void;
}

// eslint-disable-next-line react/display-name
const DatePickerInput = React.forwardRef<HTMLInputElement, InputProps>(
  (props, ref) => {
    return <Input name="select-date" {...props} ref={ref} className="input" />;
  },
);

export const BaseDatePicker = ({
  date,
  dateFormat = 'dd/MM/yyyy HH:ss aa',
  placeholderText = 'Enter select time',
  popperPlacement = 'bottom-end',
  showTimeSelect = true,
  disabled = false,
  onChange,
  ...props
}: BaseDatePickerProps) => {
  const handleDateChange = (date: Date | null): void => {
    onChange(date || undefined);
  };

  return (
    <DatePicker
      disabled={disabled}
      selected={date}
      dateFormat={dateFormat}
      timeFormat="HH:mm"
      onChange={handleDateChange}
      customInput={<DatePickerInput />}
      placeholderText={placeholderText}
      popperPlacement={popperPlacement}
      showTimeSelect={showTimeSelect}
      showPopperArrow={false}
      portalId="date-picker"
      {...props}
    />
  );
};
