import { useBoolean, useDateRangeParams } from 'src/hooks';
import { BaseDatePicker } from '../BaseDatePicker';
import React from 'react';
import { getStatisticAPIKeyApi } from 'src/handlers/apiCallHandler';
import { DateUtils } from 'src/utils';
import BaseBarChart, { BarColorsEnum, BarSeries } from '../BaseBarChart';
import { Option } from '../BaseSelectDropdown';

interface APIKeyChartProps {
  onMounted: (options: Option[]) => void;
}

const APIKeyChart = ({ onMounted }: APIKeyChartProps) => {
  const [categories, setCategories] = React.useState<string[]>([
    new Date().toISOString(),
  ]);
  const [series, setSeries] = React.useState<BarSeries>([]);
  const { state: loading } = useBoolean();
  const { queryParams, minEndDate, maxStartDate, handleQueryParamsChange } =
    useDateRangeParams();

  React.useEffect(() => {
    if (!queryParams.end_date || !queryParams.start_date) return;
    const options: Option[] = [];
    const categories: string[] = [];
    const series: BarSeries = [
      { name: 'Job Failed', data: [], color: BarColorsEnum.failed },
      { name: 'Job Running', data: [], color: BarColorsEnum.running },
      { name: 'Job Success', data: [], color: BarColorsEnum.success },
    ];

    getStatisticAPIKeyApi({
      end_date: DateUtils.toUnixTimestamp(queryParams.end_date),
      start_date: DateUtils.toUnixTimestamp(queryParams.start_date),
    })
      .then((response) => {
        response.data.forEach(
          ({ api_key, num_failed, num_running, num_success }, index) => {
            series[0].data[index] = num_failed;
            series[1].data[index] = num_running;
            series[2].data[index] = num_success;
            categories[index] = api_key.title;
            options[index] = { label: api_key.title, value: api_key.id };
          },
        );
      })
      .finally(() => {
        setCategories(categories);
        setSeries(series);
        onMounted(options);
      });
  }, [queryParams.start_date, queryParams.end_date, onMounted]);

  return (
    <div className="chart flex flex-col">
      <div className="chart-options flex-shrink-0 relative z-10">
        <div className="flex justify-end gap-2 mb-2">
          <BaseDatePicker
            placeholderText="Select Start Date"
            maxDate={maxStartDate}
            date={queryParams.start_date}
            onChange={(date) => handleQueryParamsChange('start_date', date)}
            disabled={loading}
          />
          <BaseDatePicker
            placeholderText="Select End Date"
            minDate={minEndDate}
            date={queryParams.end_date}
            onChange={(date) => handleQueryParamsChange('end_date', date)}
            disabled={loading}
          />
        </div>
      </div>
      <div className="chart-main flex-grow relative z-0">
        <BaseBarChart
          title="Statistic API Key"
          series={series}
          categories={categories}
        />
      </div>
    </div>
  );
};

export default React.memo(APIKeyChart);
