import {
  AdjustmentsHorizontalIcon,
  CodeBracketSquareIcon,
  Cog6ToothIcon,
  CpuChipIcon,
  FingerPrintIcon,
  FolderArrowDownIcon,
  KeyIcon,
  RectangleStackIcon,
  UserGroupIcon,
} from '@heroicons/react/24/solid';
import clsx from 'clsx';
import React from 'react';
import { Link } from 'react-router-dom';
import { Role } from 'src/types/common';

type Breadcrumb = {
  order: number;
  key: string;
  name: string;
  path: string;
  pathname: string;
};

type MenuItem = {
  name: string;
  path: string | string[];
  icon: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  roles: Role[];
};

interface SidebarMenuProps {
  authRole: Role;
  breadcrumb: Breadcrumb;
}

export const SidebarItemList: Record<Breadcrumb['path'], MenuItem[]> = {
  '/': [
    {
      name: 'Machines',
      path: ['/nodes', '/'],
      icon: CpuChipIcon,
      roles: [Role.ADMIN, Role.MODERATOR],
    },
    { name: 'API Keys', path: '/api-keys', icon: KeyIcon, roles: [Role.ADMIN] },
    {
      name: 'Permissions',
      path: '/permissions',
      icon: FingerPrintIcon,
      roles: [Role.ADMIN],
    },
    {
      name: 'Model Version Control',
      path: '/model-version-controls',
      icon: RectangleStackIcon,
      roles: [Role.ADMIN],
    },
    {
      name: 'Playground',
      path: '/playground',
      icon: CodeBracketSquareIcon,
      roles: [Role.ADMIN],
    },
    {
      path: '/download-files',
      icon: FolderArrowDownIcon,
      name: 'Download',
      roles: [Role.MODERATOR],
    },
  ],
  '/settings': [
    {
      name: 'General setting',
      path: '/settings',
      icon: Cog6ToothIcon,
      roles: [Role.ADMIN],
    },
    {
      name: 'Users',
      path: '/settings/users',
      icon: UserGroupIcon,
      roles: [Role.ADMIN],
    },
    {
      name: 'Permissions',
      path: '/settings/permissions',
      icon: AdjustmentsHorizontalIcon,
      roles: [Role.ADMIN],
    },
  ],
  '/dashboards': [
    {
      name: 'Statistic Number Jobs',
      path: ['/dashboards', '/dashboards/node-jobs'],
      icon: AdjustmentsHorizontalIcon,
      roles: [Role.ADMIN],
    },
    {
      name: 'Statistic Execution Time',
      path: '/dashboards/execution-time',
      icon: AdjustmentsHorizontalIcon,
      roles: [Role.ADMIN],
    },
    {
      name: 'Statistic API Key',
      path: '/dashboards/number-job-state',
      icon: AdjustmentsHorizontalIcon,
      roles: [Role.ADMIN],
    },
  ],
};

export const SidebarMenu = (props: SidebarMenuProps) => {
  const path = SidebarItemList[props.breadcrumb.path]
    ? props.breadcrumb.path
    : '/';
  return (
    <ul className="space-y-2 font-medium sm:mt-14" id="sidebar">
      {SidebarItemList[path].map(({ path, name, icon: Icon, roles }) => {
        const to = Array.isArray(path) ? path.slice(-1)[0] : path;
        return (
          <React.Fragment key={[props.breadcrumb.path] + name}>
            {roles.includes(props.authRole) ? (
              <li
                className={clsx('text-nowrap', {
                  'bg-slate-100 rounded-md': Array.isArray(path)
                    ? path.includes(props.breadcrumb.pathname)
                    : path === props.breadcrumb.pathname,
                })}>
                <Link
                  to={to}
                  className="flex items-center p-2 text-gray-900 rounded-lg group">
                  <Icon className="size-6" />
                  <span className="ms-3">{name}</span>
                </Link>
              </li>
            ) : (
              <></>
            )}
          </React.Fragment>
        );
      })}
    </ul>
  );
};
