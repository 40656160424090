import React, { useEffect, useState } from 'react';
import { useBreadcrumbs } from '../../contexts/BreadcrumbsContext';
import { useAuth } from '../../handlers/authHandler';
import { SidebarMenu } from '../SidebarMenu';

const Sidebar = () => {
  const [profile, setProfile] = useState();
  const { breadcrumbs } = useBreadcrumbs();

  const { user } = useAuth();

  useEffect(() => {
    if (user['role']) {
      setProfile(user);
      return;
    }
    user.then((data) => setProfile(data));
  }, []);

  if (!profile || !breadcrumbs.length)
    return <ul className="space-y-2 font-medium sm:mt-14" id="sidebar"></ul>;

  return <SidebarMenu authRole={profile.role} breadcrumb={breadcrumbs[0]} />;
};

export default Sidebar;
