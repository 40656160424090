import React from 'react';
import { BaseDatePicker } from '../BaseDatePicker';
import { DurationOptions, useBoolean, useDateRangeParams } from 'src/hooks';
import BaseBarChart, { BarColorsEnum, BarSeries } from '../BaseBarChart';
import { BaseSelectDropdown, Option } from '../BaseSelectDropdown';
import { getStatisticDetailAPIKeyApi } from 'src/handlers/apiCallHandler';
import { DateUtils } from 'src/utils';

interface DetailAPIKeyChartProps {
  apiKeyList: Option[];
}

const DetailAPIKeyChart = ({ apiKeyList }: DetailAPIKeyChartProps) => {
  const {
    state: loading,
    setTrue: startLoading,
    setFalse: endLoading,
  } = useBoolean();
  const { queryParams, minEndDate, maxStartDate, handleQueryParamsChange } =
    useDateRangeParams();
  const [apiKey, setAPIKey] = React.useState<string>();
  const [categories, setCategories] = React.useState<string[]>([
    new Date().toISOString(),
  ]);
  const [series, setSeries] = React.useState<BarSeries>([]);
  const firstMounted = React.useRef<boolean>(true);

  const title = apiKeyList.find((item) => item.value === apiKey)?.label;

  const formatLabel = React.useCallback((date: string | number) => {
    return DateUtils.format(new Date(date), DateUtils.FORMAT_READABLE_FULL);
  }, []);

  React.useEffect(() => {
    if (!apiKey || !queryParams.end_date || !queryParams.start_date) return;
    startLoading();

    const categories: string[] = [new Date().toISOString()];
    const series: BarSeries = [
      { name: 'Job Failed', data: [], color: BarColorsEnum.failed },
      { name: 'Job Running', data: [], color: BarColorsEnum.running },
      { name: 'Job Success', data: [], color: BarColorsEnum.success },
    ];

    getStatisticDetailAPIKeyApi({
      api_key: apiKey,
      duration: queryParams.duration,
      end_date: DateUtils.toUnixTimestamp(queryParams.end_date),
      start_date: DateUtils.toUnixTimestamp(queryParams.start_date),
    })
      .then((response) => {
        response.data.forEach(
          ({ num_failed, num_running, num_success, time }, index) => {
            series[0].data[index] = num_failed;
            series[1].data[index] = num_running;
            series[2].data[index] = num_success;
            categories[index] = time;
          },
        );
      })
      .finally(() => {
        setCategories(categories);
        setSeries(series);
        endLoading();
      });
  }, [
    apiKey,
    queryParams.duration,
    queryParams.start_date,
    queryParams.end_date,
  ]);

  React.useEffect(() => {
    if (apiKeyList.length > 0 && firstMounted.current) {
      setAPIKey(apiKeyList[0].value);
      firstMounted.current = false;
    }
  }, [apiKeyList]);

  return (
    <div className="chart flex flex-col">
      <div className="chart-options flex-shrink-0 relative z-10">
        <div className="flex justify-end gap-2 mb-2">
          <BaseSelectDropdown
            placeholderText="API Key"
            selected={apiKey}
            options={apiKeyList}
            onSelect={(option) => setAPIKey(option.value)}
          />
          <BaseSelectDropdown
            selected={queryParams.duration}
            options={DurationOptions}
            onSelect={(option) =>
              handleQueryParamsChange('duration', option.value)
            }
          />
          <BaseDatePicker
            placeholderText="Select Start Date"
            maxDate={maxStartDate}
            date={queryParams.start_date}
            onChange={(date) => handleQueryParamsChange('start_date', date)}
            disabled={loading}
          />
          <BaseDatePicker
            placeholderText="Select End Date"
            minDate={minEndDate}
            date={queryParams.end_date}
            onChange={(date) => handleQueryParamsChange('end_date', date)}
            disabled={loading}
          />
        </div>
      </div>
      <div className="chart-main flex-grow relative z-0">
        <BaseBarChart
          title={title}
          series={series}
          categories={categories}
          xaxisLabelFormate={formatLabel}
        />
      </div>
    </div>
  );
};

export default DetailAPIKeyChart;
