import React from 'react';
import { Option } from 'src/components/BaseSelectDropdown';
import { NumberJobNodeChart } from 'src/components/NumberJobNodeChart';
import { NumberJobStateInNodeChart } from 'src/components/NumberJobStateInNodeChart';
import { useTitle } from 'src/hooks';

const StatisticJobStateInNode = () => {
  useTitle('Job State In Node');

  const [nodeList, setNodeList] = React.useState<Option[]>([]);

  const handleUpdateNodeList = React.useCallback((options: Option[]) => {
    setNodeList(options);
  }, []);

  return (
    <div className="py-4 grid grid-rows-[auto_auto] gap-4 h-full *:h-[calc(100vh-64px)]">
      <NumberJobStateInNodeChart onMounted={handleUpdateNodeList} />
      <NumberJobNodeChart nodeList={nodeList} />
    </div>
  );
};

export default StatisticJobStateInNode;
