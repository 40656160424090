import { z } from 'zod';

export type ResponseFormat<T = string> = {
  status_code: number;
  message: string;
  data: T;
};

export type NodeType = {
  id: string;
  node_id: string;
  node_name: string;
};

export type APIKey = {
  id: string;
  title: string;
  description: string;
};

export type JobState = {
  num_success: number;
  num_running: number;
  num_failed: number;
};

export type InstallationGuideResponse = ResponseFormat<string>;
export type NumberJobStateInNodeResponse = ResponseFormat<
  ({ node: NodeType } & JobState)[]
>;

export type NumberJobNodeResponse = ResponseFormat<
  ({
    node_id: string;
    time: string;
  } & JobState)[]
>;

export type TotalExecutionTimeResponse = ResponseFormat<
  {
    total_execution_time: number;
    node: NodeType;
  }[]
>;

export type TotalNodeExecutionTimeResponse = ResponseFormat<{
  min_execution_time: number;
  max_execution_time: number;
  data: {
    id: string;
    execution_time: number;
    created_at: string;
  }[];
}>;

export type StatisticAPIKeyResponse = ResponseFormat<
  ({
    api_key: APIKey;
  } & JobState)[]
>;

export type StatisticDetailAPIKeyResponse = ResponseFormat<
  ({
    _id: string;
    api_key: APIKey;
    time: string;
  } & JobState)[]
>;

export const QueryParamsSchema = z
  .object({
    start_date: z.number().positive(),
    end_date: z.number().positive(),
    duration: z.enum(['day', 'month', 'year']),
  })
  .strict();

export const NodeJobStateSchema = QueryParamsSchema.omit({
  duration: true,
});

export const NodeJobStateDetailSchema = QueryParamsSchema.extend({
  node_id: z.string(),
});

export const TotalExecutionTimeSchema = QueryParamsSchema.omit({
  duration: true,
});

export const NodeExecutionTimeSchema = QueryParamsSchema.extend({
  node_id: z.string(),
}).omit({ duration: true });

export const StatisticAPIKeySchema = QueryParamsSchema.omit({
  duration: true,
});

export const StatisticDetailAPIKeySchema = QueryParamsSchema.extend({
  api_key: z.string().cuid2(),
});

export type QueryParams = z.infer<typeof QueryParamsSchema>;
export type DurationType = QueryParams['duration'];
export type NodeJobStateParams = z.infer<typeof NodeJobStateSchema>;
export type NodeJobStateDetailParams = z.infer<typeof NodeJobStateDetailSchema>;
export type TotalExecutionTimeParams = z.infer<typeof TotalExecutionTimeSchema>;
export type NodeExecutionTimeParams = z.infer<typeof NodeExecutionTimeSchema>;
export type StatisticAPIKeyParams = z.infer<typeof StatisticAPIKeySchema>;
//prettier-ignore
export type StatisticDetailAPIKeyParams = z.infer<typeof StatisticDetailAPIKeySchema>;
