import React from 'react';
import APIKeyChart from 'src/components/APIKeyChart';
import { Option } from 'src/components/BaseSelectDropdown';
import DetailAPIKeyChart from 'src/components/DetailAPIKeyChart';

const StatisticAPIKey = () => {
  const [apiKeyList, setAPIKeyList] = React.useState<Option[]>([]);

  const updateAPIKeyList = React.useCallback((options: Option[]): void => {
    setAPIKeyList(options);
  }, []);

  return (
    <div className="py-4 grid grid-rows-[auto_auto] gap-4 h-full *:h-[calc(100vh-64px)]">
      <APIKeyChart onMounted={updateAPIKeyList} />
      <DetailAPIKeyChart apiKeyList={apiKeyList} />
    </div>
  );
};

export default StatisticAPIKey;
