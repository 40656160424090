import './App.css';
import React from 'react';
import { AuthProvider } from './handlers/authHandler';
import { ProtectedRoute } from './components/ProtectedRoute';
import { Routes, Route, Navigate } from 'react-router-dom';
import Error from './pages/Error';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import NodeList from './pages/NodeList';
import NodeDetail from './pages/NodeDetail';
import { DeploymentProvider } from './contexts/DeploymentContext';
import ApiKey from './pages/ApiKey';
import ApiKeyDetail from './pages/ApiKeyDetail';
import ApiKeyCreation from './pages/ApiKeyCreation';
import Permission from './pages/Permission';
import ModelVersionControl from './pages/ModelVersionControl';
import ModelVersionControlCreation from './pages/ModelVersionControlCreation';
import ModelVersionControlDetail from './pages/ModelVersionControlDetail';
import Playground from './pages/Playground';
import GeneralSetting from './pages/GeneralSetting';
import UserSetting from './pages/UserSetting';
import PermissionSetting from './pages/PermissionSetting';
import DownloadFile from './pages/DownloadFile';
import Statistic from './pages/Statistic';
import StatisticJobStateInNode from 'src/pages/StatisticJobStateInNode';
import StatisticExecutionTime from 'src/pages/StatisticExecutionTime';
import StatisticAPIKey from './pages/StatisticAPIKey';

function App() {
  return (
    <AuthProvider>
      <Routes>
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }>
          <Route path="/" element={<NodeList />} />
          <Route path="nodes" element={<NodeList />} />
          <Route
            path="nodes/:nodeId"
            element={
              <DeploymentProvider>
                <NodeDetail />
              </DeploymentProvider>
            }
          />
          <Route path="api-keys" element={<ApiKey />} />
          <Route path="api-keys/:apiKeyId" element={<ApiKeyDetail />} />
          <Route path="api-keys/create" element={<ApiKeyCreation />} />
          <Route path="permissions" element={<Permission />} />
          <Route
            path="model-version-controls"
            element={<ModelVersionControl />}
          />
          <Route
            path="model-version-controls/:modelVersionId"
            element={<ModelVersionControlDetail />}
          />
          <Route
            path="model-version-controls/create"
            element={<ModelVersionControlCreation />}
          />
          <Route path="playground" element={<Playground />} />
          <Route path="settings" element={<GeneralSetting />} />
          <Route path="settings/users" element={<UserSetting />} />
          <Route path="settings/permissions" element={<PermissionSetting />} />
          <Route path="download-files" element={<DownloadFile />} />
          <Route path="/dashboards" element={<Statistic />}>
            <Route path="node-jobs" element={<StatisticJobStateInNode />} />
            <Route path="execution-time" element={<StatisticExecutionTime />} />
            <Route path="number-job-state" element={<StatisticAPIKey />} />
            <Route index element={<Navigate to="node-jobs" replace />} />
          </Route>
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="*" element={<Error />} />
      </Routes>
    </AuthProvider>
  );
}

export default App;
