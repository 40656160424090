import Chart from 'react-apexcharts';
import React from 'react';

export type BarSeriesItem = {
  name: string;
  data: number[];
  color?: BarColorsEnum;
};

export enum BarColorsEnum {
  failed = '#FF4560',
  success = '#00E396',
  running = '#008FFB',
}

export type BarSeries = BarSeriesItem[];

interface BaseBarChartProps {
  title?: string;
  xaxisType?: ApexXAxis['type'];
  categories: string[];
  series: BarSeries;
  stacked?: boolean;
  showLegend?: boolean;
  yaxisMin?: ApexYAxis['min'];
  yaxisMax?: ApexYAxis['max'];
  xaxisLabelFormate?: (value: string | number) => string | string[];
  yaxisLabelFormate?: (value: string | number) => string | string[];
  onSelect?: (dataPointIndex: number) => void;
}

const BaseBarChart = ({
  xaxisType = 'category',
  categories,
  series,
  title,
  stacked = true,
  showLegend = true,
  yaxisMax = undefined,
  yaxisMin = undefined,
  onSelect,
  xaxisLabelFormate,
  yaxisLabelFormate,
}: BaseBarChartProps) => {
  return (
    <Chart
      type="bar"
      height="100%"
      options={{
        chart: {
          stacked: stacked,
          toolbar: {
            show: false,
          },
          events: {
            dataPointSelection(e, c, options) {
              if (onSelect) {
                onSelect(options.dataPointIndex as number);
              }
            },
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: 40,
          },
        },
        xaxis: {
          type: xaxisType,
          categories,
          labels: {
            formatter(value) {
              return xaxisLabelFormate ? xaxisLabelFormate(value) : value;
            },
          },
        },
        yaxis: {
          labels: {
            formatter(val) {
              return yaxisLabelFormate
                ? yaxisLabelFormate(val)
                : val.toString();
            },
          },
          min: yaxisMin,
          max: yaxisMax,
        },
        colors: ['#f86734'],
        title: {
          text: title?.toUpperCase(),
          style: {
            fontFamily: 'inherit',
            fontSize: '14px',
            fontWeight: '500',
            color: 'text-gray-900',
          },
        },
        legend: {
          show: showLegend,
        },
      }}
      series={series}
    />
  );
};

export default BaseBarChart;
