import React from 'react';
import { getTotalExecutionTimeApi } from 'src/handlers/apiCallHandler';
import { useBoolean, useDateRangeParams } from 'src/hooks';
import { DateUtils } from 'src/utils';
import { BaseDatePicker } from '../BaseDatePicker';
import { Option } from '../BaseSelectDropdown';
import BaseBarChart, { BarSeries } from '../BaseBarChart';

interface ExecutionTimeChartProps {
  getNodeOptions: (options: Option[]) => void;
}

const ExecutionTimeChart = ({ getNodeOptions }: ExecutionTimeChartProps) => {
  const {
    state: loading,
    setTrue: startLoading,
    setFalse: endLoading,
  } = useBoolean();
  const { queryParams, minEndDate, maxStartDate, handleQueryParamsChange } =
    useDateRangeParams();
  const [categories, setCategories] = React.useState<string[]>([]);
  const [series, setSeries] = React.useState<BarSeries>([]);

  const formatLabel = React.useCallback((val: string | number) => {
    return val + 's';
  }, []);

  React.useEffect(() => {
    if (!queryParams.start_date || !queryParams.end_date) return;

    const series: BarSeries = [];
    const categories: string[] = [];
    const options: Option[] = [];
    startLoading();

    getTotalExecutionTimeApi({
      end_date: DateUtils.toUnixTimestamp(queryParams.end_date),
      start_date: DateUtils.toUnixTimestamp(queryParams.start_date),
    })
      .then((response) => {
        response.data.forEach((item, index) => {
          series[index] = {
            name: 'Execution time',
            data: [+item.total_execution_time.toFixed(2)],
          };
          categories[index] = item.node.node_name;
          options[index] = {
            value: item.node.node_id,
            label: item.node.node_name,
          };
        });
      })
      .finally(() => {
        setCategories(categories);
        setSeries(series);
        getNodeOptions(options);
        endLoading();
      });
  }, [queryParams.end_date, queryParams.start_date, getNodeOptions]);

  return (
    <div className="chart flex flex-col">
      <div className="chart-options flex-shrink-0">
        <div className="flex justify-end gap-2 mb-2">
          <BaseDatePicker
            placeholderText="Select Start Date"
            maxDate={maxStartDate}
            date={queryParams.start_date}
            onChange={(date) => handleQueryParamsChange('start_date', date)}
            disabled={loading}
          />
          <BaseDatePicker
            placeholderText="Select End Date"
            minDate={minEndDate}
            date={queryParams.end_date}
            onChange={(date) => handleQueryParamsChange('end_date', date)}
            disabled={loading}
          />
        </div>
      </div>
      <div className="chart-main flex-grow ">
        <BaseBarChart
          title="Statistic Execution Time"
          stacked={false}
          categories={categories}
          series={series}
          yaxisLabelFormate={formatLabel}
          showLegend
        />
      </div>
    </div>
  );
};

export default ExecutionTimeChart;
