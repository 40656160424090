import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import React from 'react';

export type Option<T = string> = {
  value: T;
  label: string;
};

interface BaseSelectDropdownProps {
  options: Option<any>[];
  selected?: Option['value'] | undefined;
  placeholderText?: string;
  onSelect: (option: Option<any>) => void;
}

export const BaseSelectDropdown = ({
  selected,
  placeholderText = 'Select your options',
  options,
  onSelect,
}: BaseSelectDropdownProps) => {
  const text = options.find((option) => option.value === selected)?.label;

  return (
    <Menu>
      <MenuButton
        disabled={!options.length}
        className="dropdown text-nowrap text-ellipsis overflow-hidden max-w-32 text-sm text-start">
        {text || placeholderText}
      </MenuButton>
      <MenuItems
        anchor="bottom"
        transition
        className="dropdown-options origin-top transition duration-200 ease-out data-[closed]:scale-95 data-[closed]:opacity-0
          mt-1 py-1 border min-w-min  border-gray-300 rounded-md  bg-white shadow-sm 
        ">
        {options.map((option) => (
          <MenuItem key={option.value}>
            <button
              aria-selected={option.value === selected}
              onClick={() => onSelect(option)}
              className="block text-start min-w-32 cursor-pointer px-2 py-1 text-sm text-gray-900 duration-100 transition-colors hover:bg-primary hover:text-white
              aria-selected:bg-primary aria-selected:text-white
              ">
              {option.label}
            </button>
          </MenuItem>
        ))}
      </MenuItems>
    </Menu>
  );
};
