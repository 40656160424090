import React from 'react';
import BaseLineChart, { LineSeriesItem } from 'src/components/BaseAreaChart';
import { getNodeExecutionTimeApi } from 'src/handlers/apiCallHandler';
import { useBoolean, useDateRangeParams } from 'src/hooks';
import { DateUtils } from 'src/utils';
import { BaseDatePicker } from '../BaseDatePicker';
import { BaseSelectDropdown, Option } from '../BaseSelectDropdown';

interface ExecutionTimeNodeChartProps {
  nodeList: Option[];
}
const ExecutionTimeNodeChart = ({ nodeList }: ExecutionTimeNodeChartProps) => {
  const {
    state: loading,
    setTrue: startLoading,
    setFalse: endLoading,
  } = useBoolean();
  const { queryParams, minEndDate, maxStartDate, handleQueryParamsChange } =
    useDateRangeParams();

  const [nodeId, setNodeId] = React.useState<Option['value']>();

  const [series, setSeries] = React.useState<LineSeriesItem[]>([]);
  const firstMounted = React.useRef<boolean>(true);
  const title = nodeList.find((item) => item.value === nodeId)?.label;

  React.useEffect(() => {
    if (!nodeId || !queryParams.start_date || !queryParams.end_date) return;

    startLoading();
    const categories: string[] = [];
    const series: LineSeriesItem[] = [{ name: 'Execution time', data: [] }];
    getNodeExecutionTimeApi({
      node_id: nodeId,
      start_date: DateUtils.toUnixTimestamp(queryParams.start_date),
      end_date: DateUtils.toUnixTimestamp(queryParams.end_date),
    })
      .then((response) => {
        response.data.data.forEach((data, index) => {
          categories[index] = data.created_at;
          series[0].data.push({
            y: +data.execution_time.toFixed(6),
            x: data.created_at,
          });
        });
      })
      .finally(() => {
        setNodeId(nodeId);
        setSeries(series);
        endLoading();
      });
  }, [nodeId, queryParams.start_date, queryParams.end_date]);

  React.useEffect(() => {
    if (nodeList.length > 0 && firstMounted.current) {
      setNodeId(nodeList[0].value);
      firstMounted.current = false;
    }
  }, [nodeList]);

  return (
    <div className="chart flex flex-col">
      <div className="chart-options flex-shrink-0">
        <div className="flex justify-end flex-nowrap gap-2 mb-2">
          <BaseSelectDropdown
            placeholderText="Select Node"
            selected={nodeId}
            options={nodeList}
            onSelect={(node) => setNodeId(node.value)}
          />
          <BaseDatePicker
            placeholderText="Select Start Date"
            maxDate={maxStartDate}
            date={queryParams.start_date}
            onChange={(date) => handleQueryParamsChange('start_date', date)}
            disabled={loading}
          />
          <BaseDatePicker
            placeholderText="Select End Date"
            minDate={minEndDate}
            date={queryParams.end_date}
            onChange={(date) => handleQueryParamsChange('end_date', date)}
            disabled={loading}
          />
        </div>
      </div>
      <div className="chart-main flex-grow ">
        <BaseLineChart title={title} series={series} />
      </div>
    </div>
  );
};

export default ExecutionTimeNodeChart;
