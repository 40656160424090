import Chart from 'react-apexcharts';
import React from 'react';
import { DateUtils } from 'src/utils';

export type LineSeriesItem = {
  name: string;
  data: {
    x: number | string;
    y: number | string;
  }[];
};

interface BaseAreaChartProps {
  title?: string;
  series: LineSeriesItem[];
  showLegend?: boolean;
  yaxisMin?: ApexYAxis['min'];
  yaxisMax?: ApexYAxis['max'];
}

const BaseAreaChart = ({
  title,
  series,
  yaxisMax,
  yaxisMin,
}: BaseAreaChartProps) => {
  return (
    <Chart
      type="area"
      series={series}
      height="100%"
      options={{
        chart: {
          type: 'area',
          zoom: {
            enabled: false,
          },
          background: 'transparent',
          toolbar: {
            tools: {
              download: false,
            },
          },
        },
        xaxis: {
          labels: {
            show: false,
            formatter(value) {
              return value
                ? DateUtils.format(new Date(value), 'dd/MM/yyyy HH:mm:ss aa')
                : value;
            },
          },
        },
        yaxis: {
          min: yaxisMin,
          max: yaxisMax,
        },
        stroke: {
          curve: 'smooth',
          width: 2,
          colors: ['#f86734'],
        },
        dataLabels: {
          enabled: false,
        },
        title: {
          text: title,
          align: 'left',
        },
        subtitle: {
          align: 'left',
        },
        grid: {
          show: true,
          borderColor: 'rgba(255, 255, 255, 0.1)',
          strokeDashArray: 0,
          position: 'front',
          xaxis: {
            lines: {
              show: true,
            },
          },
          yaxis: {
            lines: {
              show: true,
            },
          },
          row: {
            colors: undefined,
            opacity: 0.1,
          },
          column: {
            colors: undefined,
            opacity: 0.1,
          },
          padding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },
        fill: {
          colors: ['#f86734'],
        },
        legend: {
          show: false,
        },
      }}
    />
  );
};

export default BaseAreaChart;
