import { useTitle } from 'src/hooks';
import React, { useState } from 'react';
import ExecutionTimeChart from 'src/components/ExecutionTimeChart';
import { Option } from 'src/components/BaseSelectDropdown';
import ExecutionTimeNodeChart from 'src/components/ExecutionTimeNodeChart';

const StatisticExecutionTime = () => {
  useTitle('Execution Time Chart');
  const [nodeList, setNodeList] = useState<Option[]>([]);

  const updateNodeList = React.useCallback((options: Option[]) => {
    setNodeList(options);
  }, []);

  return (
    <div className="py-4 grid grid-rows-[auto_auto] gap-4 h-full *:h-[calc(100vh-64px)]">
      <ExecutionTimeChart getNodeOptions={updateNodeList} />
      <ExecutionTimeNodeChart nodeList={nodeList} />
    </div>
  );
};

export default StatisticExecutionTime;
